import moment from 'moment';
import { BaseFilter, CustomFieldType, PurchaseOrderStatus, PurchaseOrderSubscriptionAction } from '../..';
import PurchaseOrder from './PurchaseOrder';
import { FieldDateConditionType } from '../../BaseFilter';

export type PurchaseOrderFilterCustomField = {
    id: string;
    condition: string;
    value?: any;
    type: CustomFieldType;
}

export default class PurchaseOrderFilter extends BaseFilter<PurchaseOrder> {
    constructor(filter?: Partial<PurchaseOrderFilter>) {
        super();
        if (filter) this.update(filter);
    }

    statuses: PurchaseOrderStatus[] = [];
    studentId: string;
    responsibleId: string;
    studentIds: string[] = [];
    responsibleIds: string[] = [];
    fullyPaid?: boolean;
    amountFrom: number | null;
    amountTo: number | null;
    productIds: string[] = [];
    courseIds: string[] = [];
    tagIds: string[] = [];
    pipelineItemId: string;

    createdDateCondition: FieldDateConditionType | null;
    createdDateFrom: moment.Moment | null;
    createdDateTo: moment.Moment | null;

    purchasedDateCondition: FieldDateConditionType | null;
    purchasedDateFrom: moment.Moment | null;
    purchasedDateTo: moment.Moment | null;

    fullPurchasedDateCondition: FieldDateConditionType | null;
    fullPurchasedDateFrom: moment.Moment | null;
    fullPurchasedDateTo: moment.Moment | null;

    hasPayments: boolean;
    hasRenewalLicenseId: boolean;

    paidAmountFrom: number;
    paidAmountTo: number;

    utmSource: string[] = [];
    utmCampaign: string[] = [];
    utmMedium: string[] = [];
    utmContent: string[] = [];
    utmTerm: string[] = [];

    promocodeIds: string[] = [];

    partnerIds: string[] = [];
    isPartnership: boolean;
    subscriptionAction: PurchaseOrderSubscriptionAction | null;

    customFields: PurchaseOrderFilterCustomField[] = [];

    get hasAnyUtm() {
        return this.utmSource?.length > 0 ||
            this.utmCampaign?.length > 0 ||
            this.utmMedium?.length > 0 ||
            this.utmContent?.length > 0 ||
            this.utmTerm?.length > 0;
    }

    get hasCreatedDate() {
        return this.createdDateFrom || this.createdDateTo;
    }

    get hasPurchasedDate() {
        return this.purchasedDateFrom || this.purchasedDateTo;
    }

    get hasFullPurchasedDate() {
        return this.fullPurchasedDateFrom || this.fullPurchasedDateTo;
    }


    update(changes: Partial<PurchaseOrderFilter>) {
        super.update(changes);
    }

    hasChanges(filter: PurchaseOrderFilter) {
        return super.hasChanges(filter)
            || this.hasRenewalLicenseId != filter.hasRenewalLicenseId
            || this.hasAnyUtm != filter.hasAnyUtm
            || this.utmSource != filter.utmSource
            || this.utmCampaign != filter.utmCampaign
            || this.utmMedium != filter.utmMedium
            || this.utmContent != filter.utmContent
            || this.utmTerm != filter.utmTerm
            || this.tagIds != filter.tagIds
            || this.promocodeIds != filter.promocodeIds
            || this.partnerIds != filter.partnerIds
            || this.productIds != filter.productIds
            || this.isPartnership != filter.isPartnership
            || this.customFields != filter.customFields
            || this.createdDateCondition != filter.createdDateCondition
            || this.createdDateFrom != filter.createdDateFrom
            || this.createdDateTo != filter.createdDateTo
            || this.purchasedDateCondition != filter.purchasedDateCondition
            || this.purchasedDateFrom != filter.purchasedDateFrom
            || this.purchasedDateTo != filter.purchasedDateTo
            || this.fullPurchasedDateCondition != filter.fullPurchasedDateCondition
            || this.fullPurchasedDateFrom != filter.fullPurchasedDateFrom
            || this.fullPurchasedDateTo != filter.fullPurchasedDateTo
            || this.hasPayments != filter.hasPayments
            || this.fullyPaid != filter.fullyPaid
            || this.responsibleIds != filter.responsibleIds
            || this.statuses != filter.statuses
            || this.amountFrom != filter.amountFrom
            || this.amountTo != filter.amountTo
            || this.paidAmountFrom != filter.paidAmountFrom
            || this.paidAmountTo != filter.paidAmountTo
            || this.hasRenewalLicenseId != filter.hasRenewalLicenseId;
    }

    static fromJson(json: any) {
        return PurchaseOrderFilter.default({
            ...json,
            createdDateFrom: json.createdDateFrom ? moment(json.createdDateFrom) : undefined,
            createdDateTo: json.createdDateTo ? moment(json.createdDateTo) : undefined,
            purchasedDateFrom: json.purchasedDateFrom ? moment(json.purchasedDateFrom) : undefined,
            purchasedDateTo: json.purchasedDateTo ? moment(json.purchasedDateTo) : undefined,
            fullPurchasedDateFrom: json.fullPurchasedDateFrom ? moment(json.fullPurchasedDateFrom) : undefined,
            fullPurchasedDateTo: json.fullPurchasedDateTo ? moment(json.fullPurchasedDateTo) : undefined,
        });
    }

    static default(changes?: Partial<PurchaseOrderFilter>) {
        return new PurchaseOrderFilter({
            page: 1,
            customFields: [],
            ...changes
        });
    }
}

